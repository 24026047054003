// @flow
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { redirectToLogin } from "../reducers";
import { RetryLink } from "apollo-link-retry";
import {
  QUERY_SERVICE_API_URL,
  PRODUCTION
} from "../helpers/constants";

type LinkConfig = {
  uri?: string
};

// Create RetryLink to handle retries on network/connection timeouts
const retryLink = new RetryLink({
  delay: {
    initial: 1000, // Milliseconds to wait before first retry
    max: 5000, // Max number of milliseconds link should wait for any retry
    jitter: true // Randomizes delays between attempts 

    // See link below for more on option settings above and avoiding
    // potential for "Thunder Herd" after recovering from outage.
    // https://www.apollographql.com/docs/react/api/link/apollo-link-retry#avoiding-thundering-herd
  },
  attempts: {
    max: 3  // Number of retry attempts
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  /*
  onError receives a callback in the event a GraphQL or network error occurs.
  This example is a bit contrived, but in the real world, you could connect
  a logging service to the errorLink or perform a specific action in response
  to an error.
  */
  // TODO: add stackdriver.js
  if (graphQLErrors)
    graphQLErrors.map(({ message, location, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`
      )
    );

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);

    // TODO: Test for 401/403
    // There is a bug in apollo-link preventing access to statusCode
    // Many issues have been posted, here's one:
    // https://github.com/apollographql/apollo-link/issues/536
    //
    // Update: The statusCode is now available in the networkError object.

    /* eslint eqeqeq: "off" */
    if (networkError.statusCode === 401) {
      // We want to redirect to login instead of logout because
      // we can assume the user is already logged out and would
      // like to log back in.
      redirectToLogin();
    } else if (networkError.statusCode === 500) {
      console.log('500 Internal Server Error detected. Retrying...');
    }
  }
});

const queryOrMutationLink = (config: LinkConfig = {}) =>
  new ApolloLink((operation, forward) => {
    /*
    You can use a simple middleware link like this one to set credentials,
    headers, or whatever else you need on the context.
    All links in the chain will have access to the context.
    */
    operation.setContext({ credentials: "include" });

    return forward(operation);
  }).concat(
    new HttpLink({
      ...config
    })
  );

/* Instantiate apollo connection */
const client = new ApolloClient({
  credentials: "include",
  link: retryLink.concat(errorLink.concat(ApolloLink.from([
    queryOrMutationLink({ uri: QUERY_SERVICE_API_URL })
  ]))),
  connectToDevTools: !PRODUCTION,
  cache: new InMemoryCache()
});

export default client;
